/* body {
   overscroll-behavior: none;
} */

.reactFamilyTree {
   position: relative;
   width: 100%;
   height: 100%;
   overflow: hidden;
   overscroll-behavior: none;
}

.reactFamilyTree .reactFamilyTreeGraph {
   position: absolute;
   width: fit-content;
   height: auto;
   top: 0px;
   left: 0px;
   transform-origin: 50% 0px;
}

.reactFamilyTree .reactFamilyTreeGraph .emptyTreeData {
   position: relative;
   width: fit-content;
}

.reactFamilyTree .reactFamilyTreeGraph .emptyTreeData p {
   position: relative;
   width: fit-content;
   font-weight: 700;
   color: #111111;
   margin-bottom: 10px;
}

.reactFamilyTree .reactFamilyTreeGraph .emptyTreeData .itemPlusMinusIcon {
   position: relative;
   top: 0px;
   left: 50%;
   transform: translateX(-50%);
}

.reactFamilyTree .item {
   position: relative;
   width: fit-content;
   /* max-width: 460px; */
   transition: 0.3s ease;
}

.reactFamilyTree .item .itemRendered {
   position: relative;
   z-index: 1;
}

.reactFamilyTree .item .itemSearchOutline {
   --sidePadding: 6px;

   position: absolute;
   width: calc(100% + var(--sidePadding) * 2);
   height: calc(100% + var(--sidePadding) * 2);
   top: calc(var(--sidePadding) * -1);
   left: calc(var(--sidePadding) * -1);
   border: solid 2px #ff0000;
   border-radius: 10px;
   pointer-events: none;
   transition: 0.3s ease;
}

.reactFamilyTree .item .itemSearchOutline.hidden {
   opacity: 0;
}

.reactFamilyTree .item.isNotSearchResult {
   filter: grayscale(0.5) opacity(0.5) saturate(0.5);
}

.reactFamilyTree .item img {
   user-select: none;
   -webkit-user-drag: none;
}

.reactFamilyTree .gap {
   position: relative;
   /* max-width: 460px; */
}

.reactFamilyTree .pair {
   display: flex;
   position: relative;
   width: fit-content;
   /* max-width: 460px; */
   flex-direction: column;
   align-items: center;
}

.reactFamilyTree .pair .pairItems {
   display: flex;
   position: relative;
   width: fit-content;
   flex-direction: row;
   align-items: flex-start;
}

.reactFamilyTree .pair .pairItems .link {
   display: flex;
   position: relative;
   /* max-width: 460px; */
   align-self: center;
   align-items: center;
   justify-content: center;
}

.reactFamilyTree .pair .pairItems .link .linkDownDot {
   position: absolute;
   width: 10px;
   height: 10px;
   top: 50%;
   left: 50%;
   border-radius: 999px;
   transform: translate(-50%, -50%);
}

.reactFamilyTree .pair .pairItems .link .linkDown {
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
}

.reactFamilyTree .pair .pairItems .link .linkDownText {
   position: absolute;
   top: -22px;
   left: 50%;
   font-size: 9px;
   color: #11111160;
   white-space: nowrap;
   transform: translateX(-50%);
}

.reactFamilyTree .pair .pairItems .link .linkDownButton {
   display: flex;
   position: absolute;
   width: 30px;
   height: 16px;
   top: 16px;
   left: 50%;
   align-items: center;
   justify-content: center;
   background-color: red;
   border-radius: 999px;
   transform: translateX(-50%);
   z-index: 1;
   cursor: pointer;
   transition: 0.3s ease;
}

.reactFamilyTree .pair .pairItems .link .linkDownButton.inEditMode {
   top: 56px;
}

.reactFamilyTree .pair .pairItems .link .linkDownButton svg {
   position: relative;
   height: 90%;
   transition: 0.3s ease;
}

.reactFamilyTree .pair .pairItems .link .linkDownButton.opened svg {
   transform: rotate(180deg);
}

.reactFamilyTree .pair .pairChildren {
   display: flex;
   position: relative;
   width: fit-content;
   flex-direction: row;
   align-items: flex-start;
}

.reactFamilyTree .item .itemMinus {
   position: absolute;
   top: 2px;
   right: 2px;
   transition: 0.3s ease;
   z-index: 2;
}

.reactFamilyTree .item:not(.inEditMode) .itemMinus {
   opacity: 0;
   transform: translateX(-10px) translateY(10px);
   pointer-events: none;
}

.reactFamilyTree .item .itemPartnerPlus,
.reactFamilyTree .pair .pairItems .link .linkChildPlus,
.reactFamilyTree .pair .pairItems .link .linkChangeConnection,
.reactFamilyTree .item .itemParentsPlus {
   --length: 30px;

   position: absolute;
   transition: 0.3s ease;
}

.reactFamilyTree .item .itemPartnerPlus {
   width: calc(50% + var(--length));
   top: 50%;
   transform: translateY(-50%);
}

.reactFamilyTree .pair .pairItems .link .linkChildPlus,
.reactFamilyTree .pair .pairItems .link .linkChangeConnection {
   height: var(--length);
   top: 5px;
   left: 50%;
   transform-origin: top center;
   transform: rotate(45deg) translateX(-50%);
   z-index: 2;
}

.reactFamilyTree .pair .pairItems .link .linkChangeConnection {
   transform: rotate(-45deg) translateX(-50%);
}

.reactFamilyTree .item .itemParentsPlus {
   height: var(--length);
   bottom: 100%;
   left: 50%;
   transform: translateX(-50%);
}

.reactFamilyTree .pair .pairItems .link .linkChildPlus.withIcon {
   top: 10px;
}

.reactFamilyTree .pair .pairItems .link .linkChangeConnection.withIcon {
   top: 9px;
}

.reactFamilyTree .item:not(.inEditMode) .itemPartnerPlus,
.reactFamilyTree .item .itemPartnerPlus:not(.shown),
.reactFamilyTree .pair .pairItems .link .linkChildPlus:not(.shown),
.reactFamilyTree .pair .pairItems .link .linkChangeConnection:not(.shown),
.reactFamilyTree .item:not(.inEditMode) .itemParentsPlus,
.reactFamilyTree .item .itemParentsPlus:not(.shown) {
   opacity: 0;
   pointer-events: none;
}

.reactFamilyTree .item:not(.inEditMode) .itemPartnerPlus.left,
.reactFamilyTree .item .itemPartnerPlus.left:not(.shown) {
   left: 0px;
}

.reactFamilyTree .item:not(.inEditMode) .itemPartnerPlus.right,
.reactFamilyTree .item .itemPartnerPlus.right:not(.shown) {
   left: calc(50% - var(--length));
}

.reactFamilyTree .pair .pairItems .link .linkChildPlus:not(.shown) {
   top: -10px;
}

.reactFamilyTree .item .itemParentsPlus:not(.shown) {
   bottom: 60%;
}

.reactFamilyTree .item .itemPartnerPlus.left {
   left: calc(var(--length) * -1);
}

.reactFamilyTree .item .itemPartnerPlus.right {
   left: 50%;
}

.reactFamilyTree .itemPlusMinusIcon {
   display: flex;
   position: absolute;
   width: 20px;
   height: 20px;
   top: 50%;
   background-color: #ffc400;
   border-radius: 999px;
   transform: translateY(-50%);
   cursor: pointer;

   align-items: center;
   justify-content: center;
}

.reactFamilyTree .item .itemMinus .itemPlusMinusIcon {
   position: relative;
   top: 0;
   transform: none;
   background-color: #d70e0e;
}

.reactFamilyTree .item .itemPartnerPlus.left .itemPlusMinusIcon {
   left: 0;
}

.reactFamilyTree .item .itemPartnerPlus.right .itemPlusMinusIcon {
   right: 0;
}

.reactFamilyTree .pair .pairItems .link .linkChildPlus .itemPlusMinusIcon {
   left: 50%;
   transform: rotate(-45deg) translateX(calc(-50% - 5px));
}

.reactFamilyTree .pair .pairItems .link .linkChangeConnection .itemPlusMinusIcon {
   left: 50%;
   transform: rotate(45deg) translateX(calc(-50% + 9px)) translateY(calc(50% + 2.5px));
}

.reactFamilyTree .item .itemParentsPlus .itemPlusMinusIcon {
   top: 0px;
   left: 50%;
   transform: translateX(-50%) translateY(-50%);
}

.reactFamilyTree .itemPlusMinusIcon svg {
   width: 60%;
   height: 60%;
   fill: #f8f8f8;
}

.myFamilyTreeHolder {
   position: relative;
   width: 100%;
   height: 600px;
}

/*#region Main */
.button {
   display: inline-block;
   position: relative;
   width: fit-content;
   top: 0px;
   left: 0px;
   color: #f8f8f8;
   background-color: #111111;
   border-radius: 10px;
   margin-right: 16px;
   padding: 10px 28px;
   user-select: none;
   cursor: pointer;
   transition: 0.3s ease;
}

.inputField {
   position: relative;
   width: 100%;
   font-size: 16px;
   color: #f8f8f8;
   background-color: #111111;
   border-radius: 10px;
   border: none;
   outline: none;
   margin-right: 16px;
   padding: 10px 28px;
}

.inputField::placeholder {
   color: #f8f8f8a0;
}

.fileInputField {
   display: block;
   position: relative;
   top: 0px;
   left: 0px;
   width: fit-content;
   color: #f8f8f8;
   background-color: #111111;
   border-radius: 10px;
   margin-right: 16px;
   padding: 10px 28px;
   user-select: none;
   cursor: pointer;
}

.fileInputField input {
   opacity: 0;
   width: 0;
   height: 0;
}

.switch {
   --width: 60px;
   --dotSpacing: 4px;
   --dotSize: calc(var(--width) * 0.6 - var(--dotSpacing) * 2);

   display: flex;
   position: relative;
   width: fit-content;
   margin-right: 16px;

   flex-direction: row;
   align-items: center;
   gap: 10px;
}

.switch span {
   position: relative;
   color: #111111;
}

.overlayScreen .switch span {
   color: #f8f8f8;
}

.switch .switchLabel {
   display: inline-block;
   position: relative;
   width: var(--width);
   height: calc(var(--dotSize) + var(--dotSpacing) * 2);
}

.switch .switchLabel input {
   opacity: 0;
   width: 0;
   height: 0;
}

.switch .switchLabel .switchSlider {
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: #c8c8c8;
   border-radius: 999px;
   cursor: pointer;
   transition: 0.3s ease;
}

.switch .switchLabel .switchSlider:before {
   content: "";
   position: absolute;
   width: var(--dotSize);
   height: var(--dotSize);
   top: var(--dotSpacing);
   left: var(--dotSpacing);
   background-color: #f8f8f8;
   border-radius: 999px;
   transition: 0.3s ease;
}

.switch .switchLabel input:checked + .switchSlider {
   background-color: #111111;
}

.switch .switchLabel input:checked + .switchSlider:before {
   left: calc(100% - (var(--dotSize) + var(--dotSpacing)));
}

.form {
   display: flex;
   position: relative;
   margin-bottom: 20px;

   flex-direction: column;
   gap: 10px;
}

.form .formItem {
   display: flex;
   position: relative;
   width: 100%;
   flex-direction: column;
   gap: 10px;
}

.flexRow {
   display: flex;
   position: relative;
   color: #f8f8f8;

   flex-direction: row;
   align-items: center;
   gap: 10px;
}

.flexRow .inputField {
   margin-right: 0px;
}

.smallImage {
   display: block;
   position: relative;
   width: 300px;
   height: 200px;
   object-fit: contain;
   margin-bottom: 10px;
}

.chipSelector {
   display: flex;
   position: relative;
   width: 100%;
   margin-bottom: 10px;

   flex-direction: row;
   gap: 10px;
}

.flexRow .chipSelector {
   margin-bottom: 0px;
}

.chipSelector .chip {
   position: relative;
   width: fit-content;
   color: #1b1b48;
   background: #f3f5f7;
   border-radius: 999px;
   padding: 10px 20px;
   cursor: pointer;
   transition: 0.3s ease;
}

.chipSelector .chip:hover {
   filter: brightness(0.9);
}

.chipSelector .chip.active {
   background: #1b1b48;
   color: #f3f5f7;
}
/*#endregion */

/*#region Person box */
.personBox {
   display: flex;
   position: relative;
   width: 120px;
   flex-direction: column;
   align-items: center;
   gap: 8px;
}

.personBox:has(.personBoxImageBottomRightIcon.shown) {
   cursor: pointer;
}

.personBox .personBoxImageHolder {
   position: relative;
   width: 100px;
   height: 100px;
}

.personBox .personBoxImageHolder .personBoxImage {
   position: relative;
   width: 100%;
   height: 100%;
   background-color: #ffffff;
   border-style: solid;
   border-width: 3px;
   border-radius: 999px;
}

.personBox.male .personBoxImageHolder .personBoxImage {
   border-color: #75b1f2;
}

.personBox.female .personBoxImageHolder .personBoxImage {
   border-color: #fb52ac;
}

.personBox .personBoxImageHolder .personBoxImage img {
   position: relative;
   width: 100%;
   height: 100%;
   border: solid #ffffff 2px;
   border-radius: 999px;
   object-fit: cover;
   transition: 0.3s ease;
}

.personBox .personBoxImageHolder.died .personBoxImage img {
   filter: grayscale(1);
}

.personBox .personBoxImageBottomRightIcon {
   position: absolute;
   width: 16px;
   height: 16px;
   bottom: -1px;
   right: -1px;
   transition: 0.3s ease;
}

.personBox .personBoxImageBottomRightIcon:not(.shown) {
   opacity: 0;
}

.personBox .personBoxImageBottomRightIcon svg {
   position: relative;
   width: 100%;
   height: 100%;
   fill: #ffc400;
}

.personBox .personBoxMainText {
   position: relative;
   width: 100%;
   font-weight: 600;
   text-align: center;
   color: #111111;
}

.personBox .personBoxNormalText {
   position: relative;
   width: 100%;
   text-align: center;
   color: #11111180;
}
/*#endregion */

/*#region Overlay tools */
.overlayTools {
   position: fixed;
   width: fit-content;
   top: 10px;
   left: 10px;
   background-color: #f8f8f8b0;
   border-radius: 10px;
   padding: 10px;
   pointer-events: none;
   z-index: 1;
   backdrop-filter: saturate(200%) blur(14px);
}

.overlayTools .overlayToolsRow {
   position: relative;
   margin-bottom: 16px;
   pointer-events: none;
}

.overlayTools .overlayToolsRow:last-child {
   margin-bottom: 0px;
}

.overlayTools > *,
.overlayTools .overlayToolsRow > * {
   pointer-events: all;
}

.overlayToolsRow .button:last-child,
.overlayToolsRow .inputField:last-child {
   margin-right: 0px;
}
/*#endregion */

/*#region Overlay screen */
.overlayScreenHolder {
   position: fixed;
   width: 100vw;
   height: 100svh;
   top: 0px;
   left: 0px;
   background-color: #000000a0;
   pointer-events: none;
   opacity: 0;
   z-index: 1;
   transition: 0.3s ease;
}

.overlayScreenHolder .overlayScreenCloseButton {
   position: absolute;
   width: 40px;
   height: 40px;
   top: 16px;
   right: 16px;
   cursor: pointer;
}

.overlayScreenHolder .overlayScreenCloseButton svg {
   position: absolute;
   width: 100%;
   height: 100%;
   fill: #f8f8f8;
}

.overlayScreenHolder .overlayScreenCloseButton:hover svg {
   filter: brightness(0.9);
}

.overlayScreenHolder.opened {
   pointer-events: all;
   opacity: 1;
}

.overlayScreenHolder .overlayScreen {
   position: relative;
   width: calc(100% - 16px * 2);
   max-width: 900px;
   background-color: #333333;
   border-radius: 10px;
   margin: 10% auto 0px auto;
   padding: 20px 16px;
}

.overlayScreenHolder .overlayScreen .overlayScreenMainText {
   position: relative;
   width: calc(100% - 16px * 2);
   color: #f8f8f8;
   margin-left: 16px;
}

.overlayScreenHolder .overlayScreen h1.overlayScreenMainText {
   text-align: center;
   margin-bottom: 20px;
}

.overlayScreenHolder .overlayScreen .overlayScreenNormalText {
   position: relative;
   width: calc(100% - 16px * 2);
   color: #f8f8f8a0;
   margin-left: 16px;
}

.overlayScreenHolder .overlayScreen .personBox .personBoxMainText {
   color: #f8f8f8;
}

.overlayScreenHolder .overlayScreen .personBox .personBoxNormalText {
   color: #f8f8f880;
}

.overlayScreenHolder .overlayScreen .flexRow .overlayScreenNormalText,
.overlayScreenHolder .overlayScreen .flexRow .overlayScreenMainText {
   width: fit-content;
   white-space: nowrap;
}
/*#endregion */
