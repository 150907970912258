* {
   box-sizing: border-box;
}
.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.diagram-component {
   width: 100%;
   height: 100%;
   border: solid 1px black;
   background-color: white;
}

.org-chart {
   height: calc(100vh - 60px);
}
.org-chart > svg {
   height: 100%;
   /*background-color: #fff;*/
   /*background-image: url("https://via.placeholder.com/500");*/
   /* background-image: url("/organograma.png");
  background-size: cover;
  background-repeat: no-repeat; */
}
.node-container {
   min-height: 140px;
   background: #fff;
   border: 1px solid #caa45d;
   color: #0f0f1c;
   display: flex;
   justify-content: center;
   border-radius: 1rem;
}
.node-details {
   width: 100%;
   padding: 1rem;
   display: flex;
   justify-content: center;
   flex-direction: column;
}
.node-content {
   display: flex;
   align-items: center;
}
.node-team {
   width: 100%;
   text-align: center;
}
.node-team-name {
   margin-bottom: 0.5rem;
   font-size: 1.5rem;
}
.node-team-member-img {
   width: 50px;
   height: 50px;
   margin: 0.2rem;
   border-radius: 50%;
}
.node-img {
   width: 90px;
   height: 90px;
   border-radius: 1rem;
}
.node-info {
   margin-left: 1.5rem;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
}
.node-name {
   padding-bottom: 0.3rem;
   font-size: 1.5rem;
   font-weight: bold;
}
.node-role {
   padding-bottom: 0.5rem;
   font-size: 1.2rem;
}
.node-department {
   padding: 0.5rem;
   text-align: center;
   display: flex;
   align-items: center;
   background-color: #ffcb77;
   border-radius: 1rem;
   color: #227c9d;
}
.node-department > svg {
   margin-right: 0.5rem;
}
.expand-btn {
   width: 100%;
   margin: auto;
   color: #227c9d;
   background-color: #fef9ef;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #caa45d;
   border-radius: 32px;
   cursor: pointer;
}
.expand-btn .MuiSvgIcon-root {
   width: 24px;
}
.expand-btn > span:last-child {
   display: flex;
}

/* card css */
.card-close-btn {
   position: absolute;
   top: 10px;
   right: 10px;
   width: 30px;
   height: 30px;
   color: #227c9d;
   background-color: #fef9ef;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 2px solid #227c9d;
   cursor: pointer;
}
.card {
   position: absolute;
   top: 60px;
   left: 0;
   width: 25%;
   height: 75%;
   padding: 2rem;
   margin: 2rem;
   background-color: #fef9ef;
   border-radius: 1rem;
   border: 1px solid #d3d3d3;
   overflow: scroll;
}
.card-header {
   text-align: center;
   margin-bottom: 1rem;
}
.card-img {
   width: 120px;
   border-radius: 1rem;
}
.card-name {
   margin-top: 1rem;
   font-size: 1.5rem;
   font-weight: bold;
}
.card-role {
   margin: 1rem 0;
   font-size: 1.2rem;
}
.card-body {
   margin-top: 1rem;
   display: flex;
   justify-content: space-evenly;
   flex-direction: column;
}
.card-body-team-members {
   margin-top: 1rem;
   height: 26vh;
   overflow: scroll;
}
.card-item {
   width: 100%;
   margin: 0.5rem 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   font-size: 0.9rem;
}
.card-item-label {
   margin: 0.5rem 0;
   font-weight: bold;
}
.card-item-value {
   text-align: justify;
}
.card-item-team {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
}
.card-item-img {
   width: 50px;
   height: 50px;
   margin: 0.2rem;
   border-radius: 50%;
}
.card-item-name {
   margin-left: 0.5rem;
   font-weight: bold;
}
.card-item-role {
   font-size: 0.8rem;
   margin-left: 0.5rem;
}

.node-button-foreign-object {
   width: 50px;
}

.StripeElement {
   background: #e9ddc8;
   padding: 16px;
   border-radius: 8px;
}
