body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html,
body {
   margin: 0;
   padding: 0;
   height: 100%;
}

#root {
   height: 100%;
   min-height: 100%;
}

.stickyTabsHeader {
   -webkit-box-shadow: 0px 1px 34px -20px rgba(0, 0, 0, 0.75);
   -moz-box-shadow: 0px 1px 34px -20px rgba(0, 0, 0, 0.75);
   box-shadow: 0px 1px 34px -20px rgba(0, 0, 0, 0.75);
}
